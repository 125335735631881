import React from 'react'
import { fetchUtils, Admin, Resource } from 'react-admin'
import dataProvider from 'ra-data-simple-rest'


//List view
import PostAddIcon from '@material-ui/icons/PostAdd';
import NewsList from "./list_view/NewsList"
import NewsCreate from "./list_create/NewsCreate"
import NewsEdit from "./list_edit/NewsEdit"

import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AdviserList from "./list_view/AdviserList"
import AdviserCreate from "./list_create/AdviserCreate"
import AdviserEdit from "./list_edit/AdviserEdit"

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import BomList from "./list_view/BomList"
import BomCreate from "./list_create/BomCreate"
import BomEdit from "./list_edit/BomEdit"

import ClassIcon from '@material-ui/icons/Class';
import CategoryList from "./list_view/CategoryList"

import AssignmentIcon from '@material-ui/icons/Assignment';
import CountList from "./list_view/CountList"
import CountEdit from "./list_edit/CountEdit"

import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import HandbookList from "./list_view/HandbookList"
import HandbookEdit from "./list_edit/HandbookEdit"

import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PartnerList from "./list_view/PartnerList"
import PartnerCreate from "./list_create/PartnerCreate"
import PartnerEdit from "./list_edit/PartnerEdit"


//auth
const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request('https://enactus-neu.org/api/login', {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(async response => {
                if (response.status < 200 || response.status >= 300) {
                    let err = await response.json()
                
                    throw new Error(err.message)
                }
                return response.json();
            })
            .then(auth => {
                sessionStorage.setItem('jwt', JSON.stringify(auth));
            })
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            sessionStorage.removeItem('jwt');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => sessionStorage.getItem('jwt')
        ? Promise.resolve() : Promise.reject(),
    logout: () => {
        sessionStorage.removeItem('jwt');
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const { email } = JSON.parse(sessionStorage.getItem('jwt'))
            return Promise.resolve({ fullName: email });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    // authorization
    getPermissions: params => Promise.resolve(),
    
};

//send token
const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    if (sessionStorage.getItem('jwt')) {
        const { accessToken } = JSON.parse(sessionStorage.getItem('jwt'));
        options.headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return fetchUtils.fetchJson(url, options);
};


export default function AdminPage(props) {
    return(
        <div className="Admin">
            <Admin authProvider={authProvider} dataProvider={dataProvider('https://enactus-neu.org/api', httpClient)} {...props}>
                <Resource name="news" options={{ label: 'Bản tin' }} list={NewsList} create={NewsCreate} edit={NewsEdit} icon={PostAddIcon}/>
                <Resource name="category" options={{ label: 'Chuyên mục bản tin' }} list={CategoryList} icon={ClassIcon}/>
                <Resource name="adviser" options={{ label: 'Ban Cố vấn' }} list={AdviserList} create={AdviserCreate} edit={AdviserEdit} icon={EmojiPeopleIcon}/>
                <Resource name="bom" options={{ label: 'Ban Lãnh đạo' }} list={BomList} create={BomCreate} edit={BomEdit} icon={PeopleAltIcon}/>
                <Resource name="count" options={{ label: 'Tổng hợp' }} list={CountList} edit={CountEdit} icon={AssignmentIcon}/>
                <Resource name="handbook" list={HandbookList} edit={HandbookEdit} icon={ImportContactsIcon}/>
                <Resource name="partner" options={{ label: 'Đối tác' }} list={PartnerList} create={PartnerCreate} edit={PartnerEdit} icon={GroupAddIcon}/>
            </Admin>
        </div>
    )
}