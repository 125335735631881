import { Create, SimpleForm, TextInput, required, maxLength, DateInput, BooleanInput } from 'react-admin'
import RichTextInput from 'ra-input-rich-text';

export default function NewsCreate(props) {
    return(
        <Create title="Create a Post" {...props}>
            <SimpleForm>
                <TextInput source="avatar" fullWidth helperText="Up ảnh lên imgur.com rồi gắn direct link vào đây"/>
                <TextInput source="cover" fullWidth helperText="Up ảnh lên imgur.com rồi gắn direct link vào đây"/>
                <DateInput disabled source="created_time" initialValue={(Date.now())}/>
                <TextInput validate={required()} fullWidth source="category_id" />
                <TextInput validate={required()} fullWidth source="title" />
                <TextInput fullWidth source="short_name" label="Tên ngắn cho dự án, sự kiện" helperText="Chỉ dùng cho bài viết có category_id là 1, 2 hoặc 3" />
                <BooleanInput fullWidth source="is_main" helperText="Chỉ dùng cho bài viết có category_id 1 hoặc 2" />
                <TextInput validate={maxLength(150)} fullWidth source="des" label="Short description" helperText="Lưu ý: không quá 150 ký tự" />
                <RichTextInput source="brief" label="Tóm tắt dự án đang hoạt động" helperText="Lưu ý: Chỉ dùng cho bài viết thông tin dự án đang hoạt động" />
                <RichTextInput validate={required()} source="content" />
            </SimpleForm>
        </Create>
    )
}