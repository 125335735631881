import React from 'react'
import { 
    List, 
    Datagrid, 
    TextField, 
    EditButton, 
    TextInput, 
    SearchInput
} from 'react-admin'

const postFilters = [
    <SearchInput  source="title" alwaysOn />,
    <TextInput label="Category" source="category_name" />,
];

export default function NewsList(props) {
    return (
        <List {...props} filters={postFilters}>
            <Datagrid>
                <TextField source="id" />
                <TextField  source="title" />
                <TextField  source="category_name" label="Category" />
                <EditButton basePath="/news" />
            </Datagrid>
        </List>
    );
}