import { Edit, SimpleForm, TextInput } from 'react-admin'
export default function NewsCreate(props) {
    return(
        <Edit {...props}>
            <SimpleForm>
                <TextInput disabled source="id" />
                <TextInput source="name" />
                <TextInput source="img_url" />
                <TextInput source="src_url" />
            </SimpleForm>
        </Edit>
    )
}