import React from 'react'
import { List, Datagrid, TextField, EditButton } from 'react-admin'

export default function NewsList(props) {
    return (
        <List {...props}>
            <Datagrid>
                <TextField  source="id" />
                <TextField  source="name" />
                <TextField  source="img_url" />
                <TextField  source="src_url" />
                <EditButton basePath="/handbook" />
            </Datagrid>
        </List>
    );
}