import { 
    Edit, 
    SimpleForm, 
    TextInput, 
    SaveButton, 
    Toolbar,
    required

} from 'react-admin'

const number = (message = 'Must be a number') =>
    value => value && isNaN(Number(value)) ? message : undefined;


const UserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const countValidation = (value, allValues) => {
    if (!value) {
        return 'The age is required';
    }
    return undefined;
};

const validateCount = [required(), number(), countValidation]

export default function NewsCreate(props) {
    return(
        <Edit {...props}>
            <SimpleForm toolbar={<UserEditToolbar />}>
                <TextInput disabled source="id" />
                <TextInput disabled source="des" />
                <TextInput source="count" validate={validateCount} />
            </SimpleForm>
        </Edit>
    )
}