import { Create, SimpleForm, TextInput } from 'react-admin'
export default function NewsCreate(props) {
    return(
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="des" />
                <TextInput source="img_url" />
                <TextInput source="phone_num" />
            </SimpleForm>
        </Create>
    )
}