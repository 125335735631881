import { Create, SimpleForm, TextInput } from 'react-admin'
export default function NewsCreate(props) {
    return(
        <Create title="Add a new adviser" {...props}>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="img_url" />
                <TextInput source="des" />
            </SimpleForm>
        </Create>
    )
}