import React from 'react'
import { List, Datagrid, TextField } from 'react-admin'

export default function NewsList(props) {
    return (
        <List bulkActionButtons={false} {...props}>
            <Datagrid>
                <TextField source="id" />
                <TextField  source="category_name" label="Category" />
            </Datagrid>
        </List>
    );
}