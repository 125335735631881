import React from 'react'
import { List, Datagrid, TextField, EditButton } from 'react-admin'

export default function NewsList(props) {
    return (
        <List bulkActionButtons={false} {...props}>
            <Datagrid>
                <TextField source="id" />
                <TextField  label="Desc" source="des" />
                <TextField  source="count" />
                <EditButton basePath="/count" />
            </Datagrid>
        </List>
    );
}